<script setup>
  import { useWebStore } from '@/store/web.js'
  const store = useWebStore();
</script>
<template>
  <button class="pulse-orange b-r-50px p-2  no-border pointer" :class="[btnClass, black?'pulse-black bg-black w400':'pulse-orange bg-orange w300']" :style="store.sharedStyle">
    <p :class="['text-2xl',textClass]" class="InterExtraBold text-white">
      {{text===undefined?store.getLocaleString('h9'):text }}
    </p>
  </button>
</template>
<script>

export default {
  name: "EnquiryButton",
  props:[ 'text','black','btnClass','textClass'],
}
</script>